import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import { Layout } from '../components';
import { useCookie } from "../components/shared/Utils";
import { API_URL/* , CHAINS_BY_ID, PERMITTED_CHAIN_ID */ } from "../constants";
import { motion } from "framer-motion";
import moment from 'moment';
import { navigate } from 'gatsby';


/*********************************
 * Utils
 *********************************/
function lockInput(target_selector) {
	let e = document.querySelector(target_selector);
	if (!e) {
		return;
	}
	e.disabled = true;
	e.blur();
}
function lockInputs(target_selectors) {
	if (!('length' in target_selectors)) {
		return;
	}
	for (let i = 0, l = target_selectors.length; i < l; i++) {
		lockInput(target_selectors[i]);
	}
}

function unlockInput(target_selector) {
	if (!target_selector || target_selector.trim() === '') {
		return;
	}
	let e = document.querySelector(target_selector);
	if (!e) {
		return;
	}
	e.disabled = false;
	e.blur();
}
function unlockInputs(target_selectors) {
	if (!('length' in target_selectors)) {
		return;
	}
	for (let i = 0, l = target_selectors.length; i < l; i++) {
		unlockInput(target_selectors[i]);
	}
}

function findObjectByProp(arr, prop, val, strict = true) {
	return arr.find((obj) => {
		// eslint-disable-next-line eqeqeq
		return (strict && obj[prop] === val) || (!strict && obj[prop] == val);
	});
}

function showValidationMessage(text, type = 'error', target_selector, add_preload_to_target = false) {
	if (typeof target_selector === 'undefined') {
		alert(text);
	}
	console.log(target_selector);

	let target = document.querySelector(target_selector);

	if (target && target.nextSibling) {
		add_preload_to_target && target.classList.add('has-preloader');
		!add_preload_to_target && target.classList.remove('has-preloader');
		target.nextSibling.classList.remove('message-type-error');
		target.nextSibling.classList.remove('message-type-success');
		target.nextSibling.classList.remove('message-type-info');
		target.nextSibling.classList.remove('message-type-warning');
		type && target.nextSibling.classList.add(`message-type-${type}`);
		add_preload_to_target && target.nextSibling.classList.add('has-preloader');
		!add_preload_to_target && target.nextSibling.classList.remove('has-preloader');
		target.nextSibling.innerHTML = text;
	}
}

function clearValidationMessage(target_selector) {
	let target = document.querySelector(target_selector);

	if (target && target.nextSibling) {
		target.classList.remove('has-preloader');
		target.nextSibling.classList.remove('message-type-error');
		target.nextSibling.classList.remove('message-type-success');
		target.nextSibling.classList.remove('message-type-info');
		target.nextSibling.classList.remove('message-type-warning');
		target.nextSibling.classList.remove('has-preloader');
		target.nextSibling.innerHTML = '';
	}
}


// markup
const UserArea = ({ location }) => {
	const [walletChainId, setWalletChainId] = useState(0);
	const [hasAccount, setHasAccount] = useState(null);
	const [isLogged, setIsLogged] = useState(null);
	const [isWhitelisted, setIsWhitelisted] = useState(null);
	const [isPrevWhitelisted, setIsPrevWhitelisted] = useState(null);
	const [userEmailAddress, setUserEmailAddress] = useState('');
	const [userDetails, setUserDetails] = useState(null);
	const [userWalletAddress, setUserWalletAddress] = useState(null);
	const [userToken, setUserToken] = useCookie('userToken', false);
	const [userRefreshToken, setUserRefreshToken] = useState(null);
	const [isSignedUp, setIsSignedUp] = useState(false);
	const [userTransactions, setUserTransactions] = useState(null);
	const [checked, setChecked] = useState(true);

	const inputName = useRef();
	const inputPhone = useRef();
	const inputVatNumber = useRef();
	const inputAddress = useRef();


	/*********************************
	 * Check token validity
	 *********************************/
	useEffect(() => {
		console.log('Check token validity:', {userToken, userRefreshToken, isLogged, hasAccount});

		// if we have a token, check if it's valid
		if (userToken != null && userToken !== false && userToken !== 'false' && isLogged !== true && hasAccount !== true/*  && userRefreshToken == null */) {
			fetch(`${API_URL}/auth/me`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${userToken}`,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					console.log('Token valid; data:', data);
					if (data.user) {
						console.log('Save user data and update profile fields with their values', {'data.user': data.user});
						console.log('Setting userEmailAddress');
						setUserEmailAddress(data.user.email);
						console.log('Setting userWalletAddress');
						setUserWalletAddress(data.user.wallets[0].address);
						console.log('Setting isWhiteListed');
						setIsWhitelisted(data.user.whitelisted);
						console.log('Setting isPrevWhiteListed');
						setIsPrevWhitelisted(data.user.prev_whitelisted);
						console.log('Setting isLogged');
						setIsLogged(true);
						console.log('Setting hasAccount');
						setHasAccount(true);
						console.log('Getting transactions...');
						getTransactions({token: userToken}).then((tx_data) => {
							console.log('getTransactions:', tx_data);
							setUserTransactions(tx_data.result);
							// Update the profile fields, as their reference only exists from here on
							console.log('Force update to profile fields', {userDetails, 'data.user': data.user, inputName, inputPhone, inputVatNumber, inputAddress});
							console.log('Setting userDetails');
							setUserDetails(data.user);
							setChecked( tx_data.result.length > 0 );
						});
					} else {
						console.log('Insufficient data, require re-login');
						setUserToken(false);
					}
				})
				.catch((error) => {
					console.error(error);
					setUserToken(false);
				});
		} else {
			// alert('Would now redirect to LOGIN');
			navigate('/login/');
		}
	}, [userToken]);


	/*********************************
	 * Handle setting user details
	 *********************************/
	useEffect(() => {
		console.log('Handle setting user details:', {'inputName.current': inputName.current, 'inputPhone.current': inputPhone.current, 'inputVatNumber.current': inputVatNumber.current, 'inputAddress.current': inputAddress.current, userDetails});
		if (inputName.current !== undefined && inputPhone.current !== undefined && inputVatNumber.current !== undefined && inputAddress.current !== undefined) {
			inputName.current.value = userDetails.name;
			if (userDetails.extra) {
				inputPhone.current.value = userDetails.extra.phone;
				inputVatNumber.current.value = userDetails.extra.vat_number;
				inputAddress.current.value = userDetails.extra.address;
			}
		}
	}, [userDetails]);


	/*********************************
	 * Check account existence
	 *********************************/
	useEffect(() => {
		console.log('Check account existence:', {userWalletAddress, isLogged, hasAccount, userToken, "(userToken != null && userToken !== false && userToken !== 'false')": (userToken != null && userToken !== false && userToken !== 'false')});

		if (userWalletAddress == null || isLogged || hasAccount || (userToken != null && userToken !== false && userToken !== 'false')) {
			return;
		}

		// Fetch à API para verificar se utilizador já tem conta
		fetch(`${API_URL}/user/check/wallet/${userWalletAddress}`)
			.then((response) => response.json())
			.then((data) => {
				if (!data.result.valid) {
					throw new Error(`${userWalletAddress} is not a valid address!`);
				}

				setHasAccount(!!data.result.exists);
				setIsSignedUp(!!data.result.activated);

				if (data.result.exists) {
					setUserEmailAddress(data.result.email);
					setUserWalletAddress(data.result.wallet);

					if (data.result.activated) {
						setIsWhitelisted(data.result.whitelisted);
						setIsPrevWhitelisted(data.result.prev_whitelisted);
						loginOnLoad(data.result.wallet);
					}
				}
			})
			.catch((e) => {
				console.error(e);
			});

	}, [userWalletAddress, hasAccount, isLogged, userToken]);

	async function getTransactions({token}) {
		return await fetch(`${API_URL}/transactions`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => response.json())
			.then((data) => {
				console.log('Transaction get data:', data);
				return data;
			})
			.catch((error) => {
				console.log('Transaction get error:', error);
				return false;
			});
	}

	function loginOnLoad(walletAddress) {
		showValidationMessage('Requesting Metamask signature...', 'info', '#btn_metamask_login');
		// sign message to get unique signature
		try {
			window.ethereum.request({
				method: 'personal_sign',
				params: [
					walletAddress,
					'🔐  Login @ EphemeralEthernal.com',
				],
			})
			.then((signature) => {
				console.log('personal_sign response:', {signature, walletAddress});
				showValidationMessage('Signing in with Metamask...', 'info', '#btn_metamask_login');

				fetch(`${API_URL}/auth/login`, {
					method: 'POST',
					body: JSON.stringify({
						wallet: walletAddress,
						signature: signature,
					}),
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
				})
					.then((response) => {
						console.log('Login response:', response);

						return response.json();
					})
					.then((data) => {
						console.log('Login data:', data);

						if (data.error) {
							console.log('Login error:', data.error);
							showValidationMessage(data.error.message, 'error', '#btn_metamask_login');
							setIsLogged(false);
						} else if (data.errors) {
							console.log('Login errors:', data.errors);
							showValidationMessage(data.errors.join('<br>'), 'error', '#btn_metamask_login');
							setIsLogged(false);
						} else {
							console.log('Saving userToken:', {userToken, 'data.token': data.token});
							setUserToken(data.token);
							if (data.user) {
								console.log('Save user data and update profile fields with their values', {'data.user': data.user});
								console.log('Setting userEmailAddress');
								setUserEmailAddress(data.user.email);
								console.log('Setting userWalletAddress');
								setUserWalletAddress(data.user.wallets[0].address);
								console.log('Setting isLogged');
								setIsLogged(true);
								console.log('Setting hasAccount');
								setHasAccount(true);
								console.log('Setting isWhiteListed');
								setIsWhitelisted(data.user.whitelisted);
								console.log('Setting isPrevWhiteListed');
								setIsPrevWhitelisted(data.user.prev_whitelisted);
								console.log('Getting transactions...');
								getTransactions({token: data.token}).then((data) => {
									console.log('getTransactions:', data);
									setUserTransactions(data.result);
									// Update the profile fields, as their reference only exists from here on
									console.log('Force update to profile fields', {userDetails, 'data.user': data.user, inputName, inputPhone, inputVatNumber, inputAddress});
									console.log('Setting userDetails');
									setUserDetails(data.user);
									setChecked( data.result.length > 0 );
								});
							}
						}
					})
					.catch((error) => {
						showValidationMessage('Error logging in.', 'error', '#btn_metamask_login');
						console.error('Error logging in:', error);
					});
			})
			.catch((e) => {
				console.error('User rejected signing:', e);
				showValidationMessage('🦊&nbsp;&nbsp;Please, sign the request to login with Metamask <span style="font-family:Secondary-Bold">no tx cost</span>.', 'error', '#btn_metamask_login');
				return;
			});
		} catch(error) {
			console.error('Error requesting sign:', error);
			showValidationMessage('Error requesting sign.', 'error', '#btn_metamask_login');
		}
	}

	function updateProfile() {
		let userData = {
			n: inputName.current.value,
			p: inputPhone.current.value,
			v: inputVatNumber.current.value,
			a: inputAddress.current.value,
		};

		showValidationMessage('Saving profile...', 'info', '#btn_profile_save');
		lockInputs(['#btn_profile_save', '#input_profile_name', '#input_profile_phone', '#input_profile_vat_number', '#input_profile_address']);

		fetch(`${API_URL}/profile/update`, {
			method: 'POST',
			body: JSON.stringify(userData),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				if (data.error) {
					console.log(data.error);

					showValidationMessage(data.error, 'error', '#btn_profile_save');
					unlockInputs(['#btn_profile_save', '#input_profile_name', '#input_profile_phone', '#input_profile_vat_number', '#input_profile_address']);
				} else {
					showValidationMessage('Profile updated successfully', 'success', '#btn_profile_save');
					setTimeout(showValidationMessage, 4000, '&nbsp;', false, '#btn_profile_save');
					unlockInputs(['#btn_profile_save', '#input_profile_name', '#input_profile_phone', '#input_profile_vat_number', '#input_profile_address']);
				}
			})
			.catch((error) => {
				console.log(error);
				showValidationMessage(error.message, 'error', '#btn_profile_save');
				unlockInputs(['#btn_profile_save', '#input_profile_name', '#input_profile_phone', '#input_profile_vat_number', '#input_profile_address']);
			});
	}


	/*********************************
	 * Verify Wallet chain and prompt for connection to Metamask
	 *********************************/
	/* useEffect(() => {
		console.log('Verify Wallet chain and prompt for connection to Metamask');

		async function promptForConnect() {
			if (window.ethereum) {
				// prompt for connect on enter and save user's first wallet address
				let accounts = await window.ethereum.request({
					method: 'eth_requestAccounts',
				});
				if (accounts && accounts.length) {
					setUserWalletAddress(accounts[0]);
				}

				let _walletChainId = parseInt(window.ethereum.chainId, 16);
				setWalletChainId(_walletChainId);

				if (_walletChainId === PERMITTED_CHAIN_ID) {
					console.log('Wallet is on the right network');
				} else {
					console.log(`You need to change your 🦊 Metamask network to '${CHAINS_BY_ID[PERMITTED_CHAIN_ID]}'`);
				}
			}
		};

		promptForConnect();
	}, [userWalletAddress]); */


	/*********************************
	 * Listen for chainChanged event
	 *********************************/
	useEffect(() => {
		if (window.ethereum) {
			// detect Metamask account change
			/* window.ethereum.on('accountsChanged', function (accounts) {
				console.log('=====> accountsChanges',accounts);
			}); */

			// detect Network account change
			window.ethereum.on('chainChanged', function(networkId){
				console.log('=====> chainChanged',networkId);

				setWalletChainId( parseInt(window.ethereum.chainId, 16) );

				/* if (parseFloat(networkId) === PERMITTED_CHAIN_ID) {
					console.log('Wallet is on the right network');
				} else {
					console.log(`You need to change your 🦊 Metamask network to '${CHAINS_BY_ID[PERMITTED_CHAIN_ID]}'`);
				} */
			});
		} else {
			console.warn('No window.ethereum; chainChanged event NOT registered');
		}
	}, []);


	/*********************************
	 * Prompt for Metamask connect
	 *********************************/
	useEffect(() => {
		console.log('window.ethereum for promptConnect', {'window.ethereum': window.ethereum});
		if (window.ethereum) {
			// prompt for connect on enter and save user's first wallet address
			async function promptConnect() {
				console.log('promptConnect called');
				let accounts;
				try {
					accounts = await window.ethereum.request({
						method: 'eth_requestAccounts',
					});
				} catch (error) {
					console.log('===========> refused Metamask Connect');
					console.warn(error);
				}
				if (accounts && accounts.length) {
					setUserWalletAddress(accounts[0]);
				}
			}
			promptConnect();
		}
	}, []);


	return (
		<Layout location={location}>

			<Helmet>
				<link href="/assets/css/client-area.css" rel="stylesheet" type="text/css" />
			</Helmet>

			<section data-name="layout" id={'client_area_page'}>
				<div className={'page-container'}>

					{ isLogged == null || userTransactions == null ? (

						<motion.main
							style={{ opacity:0 }}

							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{
								type: "spring",
								mass: 0.35,
								stiffness: 75,
								duration: 0.5,
								delay: 0.3
							}}
						>
							{/* WAITING CALLBACK */}
							<div className={'gradient loading container-fluid'}>
								<div className={'py-5 text-center col-12'}>
									{ isLogged == null ? (
										<>
											Loading...
										</>
									) : (
										<>
											Checking for any Pre-Sale transactions...
										</>
									)}
								</div>
							</div>
						</motion.main>

					) : (

						<>

							<input type="checkbox" id="pre_sales_container" name="pre_sales" className={'d-none'} autoComplete="off" checked={checked} readOnly={true} />
							<input type="checkbox" id="profile_container" name="profile" className={'d-none'} autoComplete="off" checked={!checked} readOnly={true} />

							<div className={'d-flex'}>
								<label htmlFor="pre_sales_container" className={'btn btn--primary pre-sales-btn'} aria-label="Pre-sales" onClick={() => setChecked(checked ? false : true)}>
									Pre-sales
								</label>
								<label htmlFor="profile_container" className={'btn btn--primary profile-btn'} aria-label="Profile" onClick={() => setChecked(checked ? false : true)}>
									My Profile
								</label>
							</div>



							{/* Pre-sales */}
							<div className={'pre-sales-container container-fluid justify-content-center' + (checked ? ' d-flex' : ' d-none')}>
								{userTransactions == null || !userTransactions.length ? (
									<div className={'pre-sale-info col-10 col-xl-8'}>
										<div id={'temp_msg'} className={'profile-form'}>
											<h4 className="text-center my-3">No pre-sale purchases yet</h4>
											<div className={'text-center my-3'}>
												Subscribe to our newsletter and get updates on our Whitelist and Pre-sales.
												{/* <Link className="btn" to={'/'}>Start collecting?</Link> */}
											</div>
										</div>
									</div>
								) : (
									<>
										{userTransactions.map((tx) => {
											return <div className={'pre-sale-info col-10 col-xl-8'}>
												<div className={'title'}>
													{tx.presale.name}
												</div>
												<div className={'artist'}>
													{tx.artist.name}
												</div>
												<div className={'info-tags d-flex justify-content-around'}>
													<div>Date</div>
													<div className={'w-100'}>Action</div>
													<div>Status</div>
												</div>

												<div className={'info-process'}>
													<ul>
														{tx.statuses.map((tx_status, index) => {
															// console.log({'tx_status.status.is_pending': tx_status.status.is_pending, index, 'tx.statuses.length': tx.statuses.length});
															let status_created_at = moment.utc(tx_status.updated_at);
															let status_type = tx_status.status.is_pending && index == tx.statuses.length - 1 ? 'waiting' : 'success';
															return <li className={status_type}>
																<div>
																	<span>{status_created_at.format('DD.MM.YYYY')}</span>
																	<br/>{status_created_at.format('HH[h]mm')}
																</div>
																<div className={'w-100'}>
																	{tx_status.status.name}<br />
																	<div className={'w-100 mt-1'} style={{fontSize: '0.9em', opacity: 0.7}} dangerouslySetInnerHTML={{__html: tx_status.extra}}></div>
																</div>
																<div>
																	{status_type === 'success' ?
																		<img src={'/assets/img/icons/check_icon.svg'} alt={'info-icon'} />
																	:
																		<img src={'/assets/img/icons/loading_icon.svg'} alt={'loading-icon'} />
																	}
																</div>
															</li>
														})}
													</ul>
												</div>
											</div>
										})}
									</>
								)}

							</div>



							{/* My Profile */}
							<div className={'profile-container container-fluid justify-content-center' + (!checked ? ' d-flex' : ' d-none')}>

								<div className={'title col-10 col-xl-8'}>
									<div>Complete your profile:</div>
								</div>

								{/* <div id={'temp_msg'} className={'profile-form col-10 col-xl-8'}>
									<div>
										<h4>Updating...</h4>
										Available soon.
									</div>
								</div> */}

								<div className={''}>
									<div className={'profile-form col-10 col-xl-8'}>

										<div className={'input-container'}>
											<div>Email</div>
											<input type="text" placeholder={'Email pré-preenchido'} value={userDetails?.email} disabled={'disabled'} />
										</div>
										<div className={'input-container'}>
											<div>Name</div>
											<input id="input_profile_name" ref={inputName} type="text" placeholder={'Your name'} />
										</div>
										<div className={'input-container'}>
											<div>Phone</div>
											<input id="input_profile_phone" ref={inputPhone} type="text" placeholder={'Your phone number'} />
										</div>
										<div className={'input-container'}>
											<div>NIF (for invoices)</div>
											<input id="input_profile_vat_number" ref={inputVatNumber} type="text" placeholder={'Your NIF'} />
										</div>
										<div className={'input-container w-100'}>
											<div>Address (for invoices)</div>
											<textarea id="input_profile_address" ref={inputAddress} placeholder={'Your address'}></textarea>
										</div>
									</div>
									<div className={'col-10 col-xl-8 mx-auto'}>
										<button id="btn_profile_save" className={'btn btn--primary'} onClick={updateProfile}>Save</button>
										<div className="validation-message">&nbsp;</div>
									</div>
								</div>

							</div>
						</>

					)}


				</div>

			</section >
		</Layout >
	)
}

export default UserArea
